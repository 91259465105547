import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import materialMovementService from "../js/material-movement-service";
import Utility from "../../../shared/utility";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      sourceLPN: "",
      disableSourceLPN: false,
      destinationLoc: "",
      disableDestLoc: true,
      lpnDestLoc: "",
      disableLPNDestLoc: true,
      unitID: "",
      disableUnitID: true,
      quantity: "",
      disableQuantity: true,
      dropOffReason: "",
      reasonList: [],
      disableDropOff: true,
      disableSubmit: true,
      ceValue: 0,
      availableQty: 0,
      showDestinationLocation: false,
      showLPNInDestLocation: false,
      showUnitID: false,
      showQuantity: false,
      showReason: false,
      showSubmit: false,
      max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Value must be 50 characters or less"],
      lpnRule: [
        (v) => (v || "").length <= 39 || "Max Length of 39 character",
        (v) => !!v || "Field is required",
        (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000",
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {},
  methods: {
    //Reset Function
    resetFunction() {
      this.sourceLPN = "";
      this.disableSourceLPN = false;
      this.destinationLoc = "";
      this.disableDestLoc = true;
      this.lpnDestLoc = "";
      this.disableLPNDestLoc = true;
      this.unitID = "";
      this.disableUnitID = true;
      this.quantity = "";
      this.disableQuantity = true;
      this.dropOffReason = "";
      this.reasonList = [];
      this.disableDropOff = true;
      this.disableSubmit = true;
      this.ceValue = 0;
      this.availableQty = 0;
      this.showDestinationLocation = false;
      this.showLPNInDestLocation = false;
      this.showUnitID = false;
      this.showQuantity = false;
      this.showReason = false;
      this.showSubmit = false;
      this.$refs.sourceLPNForm.resetValidation();
      this.$refs.removePickedLPNForm.resetValidation();
    },
    //Validate Source LPN
    async validateSourceLPN() {
      if (this.$refs.sourceLPNForm.validate()) {
        let obj = {
          user_id: parseInt(this.userId),
          lpn: this.sourceLPN
        };
        let showMsg = false;
        let data = await materialMovementService.preShipLPNPickDropoff("post", obj, showMsg);
        if (data !== undefined) {
          if (data[0].value == "validated") {
            this.disableDestLoc = false;
            this.disableSourceLPN = true;
            this.destinationLoc = "";
            this.showDestinationLocation = true;
            this.showLPNInDestLocation = false;
            this.showUnitID = false;
            this.showQuantity = false;
            this.showReason = false;
            this.showSubmit = false;
          }
        }
      }
    },
    //Validate Source LPN
    async validateDestLoc() {
      let obj = {
        user_id: parseInt(this.userId),
        bin: this.destinationLoc,
        method: 1,
        pick4ship: 0,
        dropUnit: 1,
        src_lpn: this.sourceLPN,
      };
      let showMsg = false;
      let data = await materialMovementService.lpnPickDropoffValStageLoc("post", obj, showMsg);
      if (data !== undefined) {
        if (data?.Result?.length == 0) {
          this.disableLPNDestLoc = false;
          this.disableDestLoc = true;
          this.lpnDestLoc = "";
          this.showLPNInDestLocation = true;
          this.showUnitID = false;
          this.showQuantity = false;
          this.showReason = false;
          this.showSubmit = false;
        }
      }
    },
    //Validate LPN In Destination Location
    async validateLPNDestLoc() {
      if (this.$refs.LPNDestForm.validate()) {
        let obj = {
          user_id: parseInt(this.userId),
          it_lpn: this.sourceLPN,
          bin: this.destinationLoc,
          lpn: this.lpnDestLoc
        };
        let showMsg = false;
        let data = await materialMovementService.preShipDropoffValStageLPN("post", obj, showMsg);
        if (data !== undefined) {
          if (data == "Successful") {
            this.disableUnitID = false;
            this.disableLPNDestLoc = true;
            this.unitID = "";
            this.showUnitID = true;
            this.showQuantity = false;
            this.showReason = false;
            this.showSubmit = false;
          }
        }
      }
    },
    //Validate Unit ID
    async validateUnitID() {
      let obj = {
        user_id: parseInt(this.userId),
        it_lpn: this.sourceLPN,
        bin: this.destinationLoc,
        lpn: this.lpnDestLoc,
        bcn: this.unitID,
        pick4ship: 0,
        remove: 1,
      };
      let showMsg = false;
      let data = await materialMovementService.lpnPickDropoffValStageBCN("post", obj, showMsg);
      if (data !== undefined) {
        if (data.message == "Successful") {
          this.disableQuantity = false;
          this.disableUnitID = true;
          this.quantity = "";
          this.availableQty = data.avail_qty;
          this.disableDropOff = false;
          this.reasonList = data.List;
          this.showQuantity = true;
          this.showReason = true;
          this.showSubmit = false;
        }
      }
    },
    //Validate Quantity
    async validateQuantity() {
      if (this.availableQty >= this.quantity) {
        this.disableQuantity = true;
        if (this.dropOffReason !== null && this.dropOffReason !== "") {
          this.showSubmit = true;
          this.disableSubmit = false;
          this.disableDropOff = true;
        } else {
          this.showSubmit = false;
          this.disableSubmit = true;
          this.disableDropOff = false;
        }
      } else {
        this.disableDropOff = false;
        this.disableQuantity = false;
        this.dropOffReason = "";
        this.quantity = "";
        this.showSubmit = false;
        let alertMsg = "The available quantity on this LPN for this part is " + this.availableQty;
        let Alert = {
          type: "error",
          isShow: true,
          message: alertMsg,
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Drop Off dropdown change
    dropOffChange() {
      this.disableSubmit = false;
      this.disableDropOff = true;
      if (this.quantity !== null && this.quantity !== "") {
        this.disableQuantity = true;
        this.showSubmit = true;
      } else {
        this.disableQuantity = false;
        this.showSubmit = false;
      }
    },
    //Submit Call
    async removePickedLPN() {
      let obj = {
        user_id: parseInt(this.userId),
        from_lpn: this.sourceLPN,
        bcn: this.unitID,
        to_bin: this.destinationLoc,
        to_lpn: this.lpnDestLoc,
        qty: parseInt(this.quantity),
        reason_key: parseInt(this.dropOffReason),
      };
      let showMsg = true;
      let data = await materialMovementService.putAwayLPNTransferUnit("post", obj, showMsg);
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.resetFunction();
        }
      }
    },
    //To check if its a number with hyphen of not
    lpnNumFormat(event, num, loc) {
      if (loc == "source") this.sourceLPN = Utility.formatLPNNumber(event, num);
      else if (loc == "destination") this.lpnDestLoc = Utility.formatLPNNumber(event, num);
    },
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    //Add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
