import axios from 'axios';
import { bus } from '../../../main';
export default {
  // use this common funtion encrypt the local storage value

  // To add or remove Favouties
  add_remove_Favourite: function (Add, userId, route) {
    let searchObject = {
      status: Add,
      UserId: userId,
      MenuUrl: route,
    };
    axios
      .post('/ap/favorites_upsert', searchObject)
      .then((response) => {
        if (response.status == 200 || response.status == 204) {
          //Event bus will pass the values and update favourites 
          bus.$emit('favorite', response.data.body);
        }
      })
      .catch((error) => {
        console.log(error); // Do not Remove This
      });
  },
  // to get the page action
  getPageAction: async function (userId, url) {
    const res = await axios
      .get(`/ap/user_page_actions?user_id=${userId}&page_url=${url}`)
      .then((x) => x.data);
    if (!res?.body?.message) return {};
    let responsedata = await parseJSONPromise(res.body.message);
    // will return false if url is not found
    const showAdd = Boolean(
      responsedata?.Favourites?.find((x) => x.Url == url),
    );
    return {
      actionList: responsedata.Action,
      mainPage: responsedata.ParentName,
      subPage: responsedata.PageName,
      pageUrl: responsedata.PageUrl,
      favouriteList: responsedata.Favourites,
      showAdd: showAdd,
    };
  },

  getParentPageAction: async function (userId, url) {
    const res = await axios
      .get(`/ap/user_page_actions?user_id=${userId}&page_url=${url}`)
      .then((x) => x.data);
    if (!res?.body?.message) return {};
    let responsedata = await parseJSONPromise(res.body.message);
    const showAdd = Boolean(
      responsedata?.Favourites?.find((x) => x.Url == url),
    );
    // Return the object of the response
    return {
      actionList: responsedata.Action,
      mainPage: responsedata.GrandParentName,
      subPage: responsedata.ParentName,
      subPageUrl: responsedata.ParentUrl,
      pageUrl: responsedata.PageUrl,
      favouriteList: responsedata.Favourites,
      appPageName: responsedata.PageName,
      showAdd: showAdd,
    };
  },

  getLPNDetails: async function (userId) {
    const res = await axios
      .get(`/mm/lpn_initial_info?user_id=${userId}`)
      .then((x) => x.data);
    if (!res?.body?.message) return {};
    let responsedata = await parseJSONPromise(res.body.message);
    // Return the object of the response
    return responsedata
  },

};

// This Function is used to parse the data from stringify to parse
async function parseJSONPromise(data) {
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
}
